import FullPageLoader from "@Components/FullPageLoader";
import { RIA, RINA } from "@Constants/acl";
import { MAIN_LAYOUT_WRAPPER_ID } from "@Constants/index";
import { handleUrlANDUndicode } from "@Utils/index";
import { configureAxiosAuth, configureAxiosLocale } from "lib/Axios";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState, store } from "store";
//! Dayjs Common Config
import dayjs from "dayjs";
import "dayjs/locale/ar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

type Props = {
  children: JSX.Element;
  ACL?: {
    action: typeof RIA | typeof RINA;
  };
  forceLoading?: boolean;
};

const ProviderInner = ({ children, ACL, forceLoading }: Props) => {
  // const [cookieUserToken, setCookieUserToken, removeCookieUserToken] = useCookie(COOKIE_AUTH);
  // const { userData } = useAuth();
  const { locale, route, replace, pathname } = useRouter();
  const ACL_Action = ACL?.action;
  const dispatch = useDispatch<Dispatch>();
  const { isFirstRender, userToken, userData, localIpInfo } = useSelector((state: RootState) => state.user);
  const [isUserFetching, setIsUserFetching] = useState<boolean>(Boolean(ACL_Action) && Boolean(userToken));

  //! This effect will run in First Render
  useEffect(() => {
    if (isFirstRender) {
      dispatch.user._fetchUserIpInfo();
      dispatch.user.setIsFirstRender(false);
      configureAxiosAuth(userToken);
      if (userToken) {
        if (route === "/") {
          replace("/home");
        }
      }
    }
  }, []);

  //! This effect will run if userToken exist OR Changed
  useEffect(() => {
    if (userToken) {
      setIsUserFetching(true);
      dispatch.user._fetchUserData(userToken).then((responseData) => {
        if (!ACL_Action) {
          setIsUserFetching(false);
        } else {
          if (ACL_Action === RIA && responseData) {
            replace("/").then(() => setIsUserFetching(false));
          } else if (ACL_Action === RINA && !responseData) {
            if (route.includes("/profile") || pathname.includes("/[instructor_username]")) {
              document.location.href = "/home";
            } else {
              replace("/login?url=baims.com&from=web").then(() => setIsUserFetching(false));
            }
          } else {
            setIsUserFetching(false);
          }
        }
      });
    } else {
      dispatch.user.resetUserAndToken();
      if (ACL_Action === RINA) {
        if (route.includes("/profile") || pathname.includes("/[instructor_username]")) {
          document.location.href = "/home";
        } else {
          replace(
            `/login?from=web&url_redirect=${handleUrlANDUndicode(window.location.href)}&url=${handleUrlANDUndicode(
              window.location.href
            )}`
          );
        }
      }
    }
  }, [userToken]);

  //! This effect will run only if locale Changed
  useEffect(() => {
    configureAxiosLocale(locale);
  }, [locale]);

  useEffect(() => {
    // Banner Config
    if (localIpInfo) {
      dispatch.banner._checkNavbarBanner(localIpInfo);
    }
  }, [localIpInfo]);

  if (
    (isUserFetching && ACL_Action && userToken) ||
    forceLoading ||
    (!isUserFetching && ACL_Action === RINA && !userData)
  ) {
    return (
      <Fragment>
        <NextSeo title="Baims | Loading..." />
        <FullPageLoader key={"FULL_PAGE_LOADER"} />
      </Fragment>
    );
  }

  return (
    <div dir={locale === "ar" ? "rtl" : "ltr"} id={MAIN_LAYOUT_WRAPPER_ID}>
      {children}
    </div>
  );
};

export default function GlobalStateProvider({ children, ACL }: Props) {
  return (
    <Provider store={store}>
      <ProviderInner ACL={ACL}>{children}</ProviderInner>
    </Provider>
  );
}
