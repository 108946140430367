
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
// @ts-nocheck
import GlobalStateProvider from "@Components/containers/GlobalStateProvider";
import { COOKIE_LANG, USER_DEVICE_ID } from "@Constants/index";
import { LOGIN_REDIRECT_URL } from "@Constants/loginPopupCases";
import ReactQueryConfig from "@Constants/ReactQueryConfig";
import { GlobalStyle } from "@Style/GlobalStyle";
import { grid } from "@Style/grid";
import theme from "@Style/theme";
import "assets/stylesheets/fonts.css";
import Axios from "lib/Axios";
import { FacebookPixelProvider } from "lib/providers/FacebookPixelProvider";
import { DefaultSeo, SocialProfileJsonLd } from "next-seo";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { useCookie, useLocalStorage } from "react-use";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import stylisRTLCSS from "stylis-rtlcss";
import { v4 as uuidv4 } from "uuid";
import "../lib/Firebase";
import * as gtm from "../lib/GoogleTagManager";
import { IntercomProvider } from "../lib/providers/IntercomProvider";
import SEO from "../next-seo.config";
Axios.defaults.baseURL = process.env.NEXT_PUBLIC_URL_API;

const __Page_Next_Translate__ = function MyApp({ Component: Page, pageProps, router: { locale } }: AppProps) {
  Axios.defaults.headers.common["client-secret"] = process.env.NEXT_PUBLIC_CLIENT_SECRET;
  Axios.defaults.headers.common["Content-Language"] = locale;
  Axios.defaults.headers.common["Accept-Language"] = locale;
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient(ReactQueryConfig));
  const [cookieLang, setCookieLang] = useCookie(COOKIE_LANG);
  const [manualLoading, setManualLoading] = useState(false);
  const [, setLoginRedirectUrl] = useLocalStorage(LOGIN_REDIRECT_URL);

  useEffect(() => {
    setCookieLang(router.locale);
    if (!cookieLang) {
      setCookieLang(router.locale);
    } else {
      if (router.locale !== cookieLang && router.locales?.includes(cookieLang)) {
        setManualLoading(true);
        router
          .push({ pathname: router.pathname, query: router.query }, router.asPath, { locale: router.locale })
          .then(() => setManualLoading(false));
      }
    }
  }, [router.pathname]);

  useEffect(() => {
    if (typeof window !== "undefined" && !localStorage.getItem(USER_DEVICE_ID)) {
      localStorage.setItem(USER_DEVICE_ID, uuidv4());
    }
  }, []);

  // Google Tag Manager, Hotjar, Twitter pixel, Snap pixel
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_PROJECT_ENVIRONMENT === "production") {
      hotjar.initialize(`${process.env.NEXT_PUBLIC_HOTJAR_ID}`, 6);
      const handleRouteChange = (url: string) => {
        if (typeof window !== "undefined") {
          gtm.pageview(url);
          if (twq) {
            twq("track", "PageView");
          }
          if (hotjar.initialized()) {
            hotjar.stateChange(url);
          }
          if (snaptr) {
            snaptr("track", "PAGE_VIEW");
          }
        }
      };
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router.events]);

  return (
    <GlobalStateProvider ACL={pageProps.ACL} forceLoading={manualLoading}>
      <IntercomProvider>
        <FacebookPixelProvider>
          <DefaultSeo {...SEO} />
          <SocialProfileJsonLd
            type="organization"
            name="Baims"
            url="http://baims.com/"
            sameAs={[
              "https://twitter.com/baims",
              "https://www.instagram.com/baims/",
              "https://www.facebook.com/baims/",
              "https://www.linkedin.com/baims",
            ]}
          />
          <StyleSheetManager stylisPlugins={locale === "ar" && router.route !== "/login" ? [stylisRTLCSS] : []}>
            <ThemeProvider theme={theme}>
              <GridThemeProvider gridTheme={grid}>
                <QueryClientProvider client={queryClient}>
                  <Hydrate state={pageProps.dehydratedState}>
                    <GlobalStyle />
                    {/* <NextNprogress height={4} color={theme.colors.brandColor} /> */}
                    <Page {...pageProps} />
                  </Hydrate>
                </QueryClientProvider>
              </GridThemeProvider>
              <ToastContainer hideProgressBar newestOnTop autoClose={4000} limit={3} closeOnClick />
            </ThemeProvider>
          </StyleSheetManager>
        </FacebookPixelProvider>
      </IntercomProvider>
    </GlobalStateProvider>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  