import {
  COMPLETED_PROFILE,
  COOKIE_AUTH,
  DEFAULT_COOKIE_DOMAIN_SETTINGS,
  HAS_PASSWORD,
  KSA_COUNTRY_CODE,
  LOCALSTORAGE_IP_INFO,
} from "@Constants/index";
import { _GetUserLocation } from "@Services/Misc";
import { getProfile, postDeviceID } from "@Services/User";
import { redirectValidateReloadHandler } from "@Utils/index";
import { createModel } from "@rematch/core";
import Cookies from "js-cookie";
import { configureAxiosAuth } from "lib/Axios";
import { recentlyLogin } from "lib/GoogleTagManager";
import { snapSignupEvent } from "lib/SnapchatPixel";
import { GeneralIP_Info } from "types/IPRegistert";
import { UserAPIData, UserProfile } from "types/users";
import { RootModel } from ".";

// undefined => Model Didn't fired yet
// has data => the model fired and fetched the data
// null => the model fired and no data found

// Function that starts with "_", is an async function (effect)
type UserModel = {
  isFirstRender: boolean;
  userToken: string | null;
  loginModal: boolean;
  // subscriptions?: UserSubscription[] | null;

  userData?: UserProfile;
  localIpInfo?: GeneralIP_Info;
};

const User = createModel<RootModel>()({
  name: "user",
  state: {
    isFirstRender: true,
    userToken: Cookies.get(COOKIE_AUTH) || null,
    localIpInfo: typeof window !== "undefined" && JSON.parse(window.localStorage.getItem(LOCALSTORAGE_IP_INFO)),
    loginModal: false,
  } as UserModel,
  reducers: {
    setIsFirstRender: (state, payload: boolean) => {
      state.isFirstRender = payload;
    },
    setToken: (state, payload: string | null) => {
      state.userToken = payload;
      if (payload) {
        configureAxiosAuth(payload);
        Cookies.set(COOKIE_AUTH, payload, DEFAULT_COOKIE_DOMAIN_SETTINGS);
      } else {
        configureAxiosAuth();
        Cookies.remove(COOKIE_AUTH, DEFAULT_COOKIE_DOMAIN_SETTINGS);
      }
    },
    setUserIpInfo: (state, payload: GeneralIP_Info | null) => {
      window.localStorage.setItem(LOCALSTORAGE_IP_INFO, JSON.stringify(payload));
      state.localIpInfo = payload;
    },
    setUserData: (state, payload: UserProfile | null) => {
      state.userData = payload;
    },
    resetUserAndToken: (state) => {
      state.userData = null;
      state.userToken = null;
      Cookies.remove(COOKIE_AUTH);
      Cookies.remove(COOKIE_AUTH, DEFAULT_COOKIE_DOMAIN_SETTINGS);
      if (typeof window !== "undefined") {
        localStorage.removeItem(HAS_PASSWORD);
        window.localStorage.setItem("phoneVerified", JSON.stringify(false));
      }
      configureAxiosAuth();
    },
    setLoginModal: (state, payload: boolean) => {
      state.loginModal = payload;
    },
    // setSubscriptions: (state, payload: UserModel["subscriptions"]) => {
    //   state.subscriptions = payload;
    // },
  },
  effects: (dispatch) => ({
    async _fetchUserIpInfo() {
      try {
        const data = await _GetUserLocation();
        if (data?.data) {
          dispatch.user.setUserIpInfo(data.data);
        } else {
          dispatch.user.setUserIpInfo(null);
        }
      } catch (error) {
        dispatch.user.setUserIpInfo(null);
      }
    },
    async _socialHelper(
      {
        userLoginData,
        loginType,
        hasCompletedProfile,
        access_token,
      }: {
        userLoginData: UserAPIData;
        loginType?: "email" | "twitter" | "apple" | "google" | "phone-number" | "reset-password";
        hasCompletedProfile?: boolean;
        access_token?: string;
      },
      state
    ) {
      if (typeof window !== "undefined") {
        localStorage.setItem(COMPLETED_PROFILE, String(userLoginData?.has_completed_profile));
        localStorage.setItem(HAS_PASSWORD, String(userLoginData?.has_password));
        Cookies.set(
          COOKIE_AUTH,
          access_token ? access_token : userLoginData?.access_token,
          DEFAULT_COOKIE_DOMAIN_SETTINGS
        );
      }
      try {
        await postDeviceID();
        if (userLoginData?.is_first_day) {
          recentlyLogin({
            user_id: userLoginData.user?.id,
            user_name: userLoginData.user?.username,
            user_email: userLoginData.user?.email,
            singup_type: loginType || "",
          });
        }

        if (userLoginData?.is_fresh && state?.user?.localIpInfo?.country_code === KSA_COUNTRY_CODE) {
          snapSignupEvent({
            id: userLoginData.user?.id?.toString(),
            username: userLoginData.user?.username,
            email: userLoginData.user?.email,
            signup_method: loginType || "",
          });
        }
      } catch (error) {
        console.log("Error in Social Helper: ", error);
      }
      if (hasCompletedProfile) {
        redirectValidateReloadHandler(
          access_token ? access_token : userLoginData?.access_token,
          true,
          "url" || "url_redirect"
        );
      }
      // else {
      //   setState(SET_NEW_ACCOUNT);
      // }
    },
    async _fetchUserData(userToken: string) {
      try {
        const data = await getProfile(userToken);
        if (data?.data?.data) {
          dispatch.user.setUserData(data.data.data);
          return data.data.data;
        } else {
          dispatch.user.resetUserAndToken();
          return null;
        }
      } catch (error) {
        dispatch.user.resetUserAndToken();
        return null;
      }
    },
    async _logout(options?: { noReload: boolean }) {
      dispatch.user.resetUserAndToken();
      try {
        await postDeviceID();
      } catch (error) {
        console.log("Error in Logout Device ID: ", error);
      }
      if (!options?.noReload) {
        redirectValidateReloadHandler(null, false, "url" || "url_redirect");
      }
    },
    // async _getSubscriptions(userToken?: string) {
    //   try {
    //     const data = await _GetUserSubscriptions(userToken);
    //     if (data) {
    //       dispatch.user.setSubscriptions(data);
    //     }
    //   } catch (error) {
    //     dispatch.user.setSubscriptions(null);
    //   }
    // },
  }),
  // selectors: (slice, createSelector, hasProps) => ({
  //   isSubscribed: hasProps(function (models, subjectId) {
  //     return slice((items) => items.subscriptions.find((item) => item.id === subjectId));
  //   }),
  // }),
});

export default User;
