import { LOGIN_REDIRECT_URL } from "@Constants/loginPopupCases";
import { cookieGetLang } from "@Utils/Locale";

// Check URL is include dev.
export const isDev = () => window.location.href.includes("dev.");

// Check URL is include ar
export const isAr = () => (cookieGetLang() == "ar" ? true : false);

// Get device id for current navigator
export const deviceId = () =>
  navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.filter((device) => device.kind === "audiooutput").map((device) => device.deviceId));

// Check URL is include setpassword query
export const isSetPassword = () => window.location.search.includes("setpassword");

// Convert number to 2 digits ---> ex: 8 = 08
export const convDigits = (num: number) => `${Math.trunc(num / 10)}${Math.trunc(num % 10)}`;

// Find object by id in an array of objects
export const findObjectByID = (arr: [], id: number) => arr.filter((x: any) => x.id === id)[0];

// Get Query Value
export const queryValue = (query, url) => {
  if (url) {
    let storageItem = url.replace(/['"]+/g, "");
    // run against regex
    const matches = storageItem.match(/^([^\/?#]+)(?:[\/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    let hostnameGetter = matches && matches[1];
    const matchesQuery = storageItem.split(hostnameGetter)[1];

    if (query == "url") {
      return hostnameGetter;
    } else {
      return matchesQuery.length == 0 ? "/" : matchesQuery;
    }
  }
};

// Query Value Finder
export const queryValueFinder = (query, url) => {
  if (url) {
    let sPageURL = url.replace(/['"]+/g, "").substring(1);
    let sURLVariables = sPageURL.split("&");
    let sParameterName;
    let i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === query) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]).replace(/(^\w+:|^)\/\//, "");
      }
    }
  }
};

export const queryAvalible = (query) => {
  let field = query;
  let url = window.location.href;
  if (url.indexOf("?" + field + "=") != -1) return true;
  else if (url.indexOf("&" + field + "=") != -1) return true;
  return false;
};

// redirectVlue Handler
export const redirectValidateReloadHandler = (
  access_token,
  has_completed_profile = true,
  queryFinder = "url" || "url_redirect"
) => {
  if (queryAvalible(queryFinder) && getLocalStorageItem(LOGIN_REDIRECT_URL)) {
    window.location.href = `https://${getLocalStorageItem(LOGIN_REDIRECT_URL)}`;
  } else {
    window.location.reload();
  }
};

// Generate /redirect/ptah&token url
export const generateRedirectLinkHandler = (urlDetails, pathUrl, access_token, usePathname) =>
  `https://${urlDetails}/redirect?path=${
    usePathname ? new URL(pathUrl).pathname : pathUrl
  }&locale=${cookieGetLang()}&token=${access_token}`;

// Get Localstorage item name
export const getLocalStorageItem = (itemName) => localStorage.getItem(itemName).replace(/['"]+/g, "");

// replace string first letter (ex: live-session ==> liveSession)
export const replaceStringFirstLetter = (str: string | string[]) =>
  (str as string).replace(/(-)\S/g, (letter) => letter.toUpperCase()).replace(/\-/g, "");

// Get first item if not type package
export const firstItem = (arr) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("type");

  return arr[0][replaceStringFirstLetter(myParam)];
};

// Generate Payment Link from id, type, url_redirect
export const paymentLinkGenerate = (id, type, slug, url_redirect, packageOptionId, isTamara) => {
  const generatedUrl = `${process.env.NEXT_PUBLIC_APP_URL}/${isAr() ? "ar/" : ""}payment?id=${id}&type=${type}${
    packageOptionId ? `&package_option_id=${packageOptionId}` : ""
  }${Boolean(isTamara) ? `&is_tamara=${isTamara}` : ""}&${slug ? `slug=${slug}&` : ""}url_redirect=${url_redirect}`;

  return generatedUrl;
};

// Generate Payment Status Link from id, type, url_redirect
export const paymentStatusLinkGenerate = (id, is_boubyan, type, url_redirect, status, slug, invoice_code) => {
  const generatedUrl = `${process.env.NEXT_PUBLIC_APP_URL}/${
    isAr() ? "ar/" : ""
  }payment/status?id=${id}&type=${type}&startwatchUrl=${url_redirect}&slug=${slug}&status=${status}&invoice_code=${invoice_code}&is_boubyan=${
    is_boubyan ? is_boubyan : "false"
  }`;

  return generatedUrl;
};

// Handle Price
export const priceFreeHandler = (price, currency, t) => (price == 0 ? t("free") : `${price} ${currency}`);

// convert & to %26
export const handleUrlANDUndicode = (url) => url.replace(/(^\w+:|^)\/\//, "");
