import { cookieGetLang } from "@Utils/Locale";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const pageview = (url: string) => {
  window.dataLayer.push({
    event: "pageview",
    page: url,
    page_type: cookieGetLang(),
  });
};

export const purchaseEvent = ({ currency, value, id, type, status, user_name, user_id, user_email }: any) => {
  gtag("event", "purchase", {
    currency,
    value,
    id,
    type,
    status,
    user_name,
    user_id,
    user_email,
    page_type: cookieGetLang(),
    ecommerce: {
      transaction_id: id,
      value,
      currency,
    },
  });
};

export const paymentInfoEvent = ({
  payment_type,
  coupon,
  value,
  discount,
  currency,
  item_id,
  item_name,
  item_type,
  user_id,
  user_name,
  user_email,
}: any) => {
  gtag("event", "add_payment_info", {
    payment_type,
    coupon,
    value,
    discount,
    currency,
    item_id,
    item_name,
    item_type,
    user_id,
    user_name,
    user_email,
    page_type: cookieGetLang(),
  });
};

export const recentlyLogin = ({
  user_id,
  user_name,
  user_email,
  singup_type,
}: {
  user_id: string | number;
  user_name: string;
  user_email: string;
  singup_type?: string;
}) => {
  gtag("event", "signup", {
    user_id,
    user_name,
    user_email,
    page_type: cookieGetLang(),
    singup_type,
  });
};

export const freeCourseClick = ({ courseName }: { courseName: string }) => {
  gtag("event", "free_course_click", {
    course_type: "Free Courses ",
    course_name: courseName,
    page_language: cookieGetLang(),
  });
};

export const freeCourseJoin = ({ courseName }: { courseName: string }) => {
  gtag("event", "free_course_join", {
    course_type: "Free Courses ",
    course_name: courseName,
    page_language: cookieGetLang(),
  });
};
